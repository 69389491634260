import React from 'react';

import Image from 'next/image';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {colors} from '@stryd/react-ui';

import {withBasePath} from 'src/utils/router';

import {ExternalLink} from '../ExternalLink';

import {DefaultSectionPadding} from './DefaultSectionPadding';
import NewsletterSignUp from './NewsLetterSignUp';

const emojiWidth = '1.5rem';

const FooterContainer = styled(DefaultSectionPadding)`
  max-width: 1440px;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: ${emojiWidth};
`;

const LinksList = styled.div`
  margin-right: 70px;
  margin-bottom: 3em;
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;

  & > a {
    margin-bottom: 12px;
  }

  @media screen and (max-width: 1200px) {
    margin-right: 60px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 2em;
  }
`;

const SignUpContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  min-width: 450px;

  @media screen and (max-width: 1024px) {
    width: 50%;
    padding-right: ${emojiWidth};
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-right: ${emojiWidth};
  }

  @media screen and (max-width: 500px) {
    min-width: unset;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  width: ${emojiWidth};
  height: ${emojiWidth};
  left: calc(-${emojiWidth} - 5px);
`;

const FooterHeader = styled.div`
  color: ${colors.themes.dark.textInverted};
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
`;

const FooterRowContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

type FooterHeaderRowProps = {src: string; alt: string};

const FooterHeaderRow: React.FC<FooterHeaderRowProps> = ({
  children,
  ...imageProps
}) => (
  <FooterRowContainer>
    <ImageContainer>
      <Image {...imageProps} layout={'fill'} objectFit={'contain'} />
    </ImageContainer>
    <FooterHeader>{children}</FooterHeader>
  </FooterRowContainer>
);

const Footer: React.FC = () => {
  return (
    <footer>
      <FooterContainer>
        <FlexRow>
          <LinksList>
            <FooterHeaderRow
              src={withBasePath(`/icons/icon-help.png`)}
              alt={'Help icon'}
            >
              <FormattedMessage id="Footer.help" defaultMessage="HELP" />
            </FooterHeaderRow>
            <ExternalLink
              href="https://help.stryd.com/en/articles/6879096-faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="Footer.faq" defaultMessage="FAQ" />
            </ExternalLink>
            <ExternalLink
              href="https://www.youtube.com/channel/UCLcAyUN3rDsagHbPA0z1C3g/playlists"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="Footer.howTo"
                defaultMessage="How-to Videos"
              />
            </ExternalLink>
            <ExternalLink
              href="https://help.stryd.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="Footer.support" defaultMessage="Support" />
            </ExternalLink>
            <ExternalLink href="https://help.stryd.com/en/articles/8126060-how-can-i-contact-stryd-for-support">
              <FormattedMessage id="Footer.email" defaultMessage="Email Us" />
            </ExternalLink>
            <ExternalLink
              href="https://drive.google.com/drive/folders/1CR0cPfPXL2VCPe4wv-ArcuJtlpVsFEej?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="Footer.media" defaultMessage="Media" />
            </ExternalLink>
          </LinksList>
          <LinksList>
            <FooterHeaderRow
              src={withBasePath(`/icons/icon-education.png`)}
              alt={'Book icon'}
            >
              <FormattedMessage
                id="Footer.education"
                defaultMessage="EDUCATION"
              />
            </FooterHeaderRow>
            <ExternalLink
              href="https://www.youtube.com/channel/UCLcAyUN3rDsagHbPA0z1C3g/videos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="Footer.webinars"
                defaultMessage="Webinars"
              />
            </ExternalLink>
            <ExternalLink
              href="https://blog.stryd.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="Footer.blog" defaultMessage="Blog" />
            </ExternalLink>
          </LinksList>
          <LinksList>
            <FooterHeaderRow
              src={withBasePath(`/icons/icon-community.png`)}
              alt={'Community icon'}
            >
              <FormattedMessage
                id="Footer.community"
                defaultMessage="COMMUNITY"
              />
            </FooterHeaderRow>
            <ExternalLink
              href="https://www.facebook.com/groups/strydcommunity"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="Footer.facebook"
                defaultMessage="Facebook Community"
              />
            </ExternalLink>
            <ExternalLink
              href="https://www.instagram.com/strydrunning/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="Footer.instagram"
                defaultMessage="#Runwithpower"
              />
            </ExternalLink>
            <ExternalLink
              href="https://stryd.com/coaches"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="Footer.findCoach"
                defaultMessage="Stryd Power Coaching Group"
              />
            </ExternalLink>
            <ExternalLink
              href="https://stryd.com/diversity"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="Footer.diversity"
                defaultMessage="Diversity"
              />
            </ExternalLink>
            <ExternalLink
              href="https://stryd.com/jobs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="Footer.jobs" defaultMessage="Jobs" />
            </ExternalLink>
          </LinksList>
          <SignUpContainer>
            <FooterHeaderRow
              src={withBasePath(`/icons/icon-bolt.png`)}
              alt={'Power icon'}
            >
              <FormattedMessage
                id="Footer.newsletterHeading"
                defaultMessage=" STRYD NEWS, UPDATES, AND TIPS"
              />
            </FooterHeaderRow>
            <NewsletterSignUp
              listURL="https://stryd.us7.list-manage.com/subscribe/post?u=082dda916c00d472b4e61d119&amp;id=70bc062aad"
              btnTextColor="white"
              btnBackgroundColor={colors.themes.dark.textHighlight}
              btnHoverBackgroundColor={colors.themes.dark.textHighlightHover}
            />
          </SignUpContainer>
        </FlexRow>
      </FooterContainer>
    </footer>
  );
};

export default Footer;
