import {defineMessage, MessageDescriptor} from 'react-intl';
import {useIntl} from 'react-intl';

export interface NavigationElement {
  messageDescriptor: MessageDescriptor;
  href: string;
  highlight?: boolean;
}

interface NavSections {
  topNavBar: NavigationElement[];
}

const navSections = {
  topNavBar: [
    {
      messageDescriptor: defineMessage({
        id: 'TopNav.Home',
        defaultMessage: 'Home',
      }),
      href: '/',
    },
    {
      messageDescriptor: defineMessage({
        id: 'TopNav.Blog',
        defaultMessage: 'Blog',
      }),
      href: 'https://blog.stryd.com/',
    },
    {
      messageDescriptor: defineMessage({
        id: 'TopNav.Support',
        defaultMessage: 'Support',
      }),
      href: 'https://help.stryd.com',
    },
    {
      messageDescriptor: defineMessage({
        id: 'TopNav.Coaches',
        defaultMessage: 'Coaches',
      }),
      href: '/coaches',
    },
    {
      messageDescriptor: defineMessage({
        id: 'TopNav.BuyStryd',
        defaultMessage: 'Buy Stryd',
      }),
      href: '/store',
      highlight: true,
    },
    {
      messageDescriptor: defineMessage({
        id: 'TopNav.PowerCenter',
        defaultMessage: 'PowerCenter',
      }),
      href: 'https://www.stryd.com/powercenter',
    },
  ],
};

export const useNavigation = (
  id: keyof NavSections
): {
  text: string;
  href: string;
  highlight?: boolean;
}[] => {
  const intl = useIntl();

  return navSections[id].map((el) => {
    return {
      text: intl.formatMessage(el.messageDescriptor),
      href: el.href,
      highlight: el.highlight,
    };
  });
};
